import { graphql, useStaticQuery } from "gatsby";
import { WpMenuItem } from "@types";

export const FeaturesNavQuery = (): { menu: WpMenuItem[] } => {
  const {
    wpMenu: {
      menuItems: { nodes },
    },
  } = useStaticQuery(graphql`
    {
      wpMenu(id: { eq: "dGVybToxMQ==" }) {
        menuItems {
          nodes {
            id
            cssClasses
            parentId
            label
            path
            description
          }
        }
      }
    }
  `);
  return {
    menu: nodes,
  };
};
