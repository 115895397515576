import React, { useRef } from "react";

import useBrowser from "../../hooks/useBrowser";
import HeroWithVideo from "../../components/Heros/HeroWithVideo";
import { HeroPType } from "../../assets/Types/AcfGroupTypes/HeroP.type";
import { HeroDataType } from "../../components/Heros/HeroWithVideo/heroWithVideo.types";

type AnimationType = {
  animationPoster: {
    mediaItemUrl: string;
  };
  animationSrcMp4: {
    mediaItemUrl: string;
  };
  animationSrcWebm: {
    mediaItemUrl: string;
  };
};

type HeroWithVideoContainerProps = {
  heroP: HeroPType;
  animation?: AnimationType;
  isSmTitle?: boolean;
};

const HeroWithVideoContainer: React.FC<HeroWithVideoContainerProps> = ({
  heroP,
  animation,
  isSmTitle,
}) => {
  const { isChrome } = useBrowser();

  let heroData = useRef<HeroDataType>({
    ...heroP,
    heroAnimation: {
      src: "",
      type: "video/mp4",
      poster: "",
    },
  });

  heroData.current = {
    ...heroData.current,
    heroAnimation: {
      src: !isChrome
        ? animation.animationSrcMp4?.mediaItemUrl
        : animation.animationSrcWebm?.mediaItemUrl,
      type: !isChrome ? "video/mp4" : "video/webm",
      poster: animation.animationPoster?.mediaItemUrl,
    },
  };

  return <HeroWithVideo heroData={heroData.current} isSmTitle={isSmTitle} />;
};

export default HeroWithVideoContainer;
