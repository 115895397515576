import * as React from "react";
import { Link } from "gatsby";
import StarYellow from "../SVGIcons/ReviewStars/StarYellow";
import StarYellowPartial from "../SVGIcons/ReviewStars/StarYellowPartial";

import styles from "./StarsRating.module.scss";

type StarsRatingType = {
  link?: string;
  className?: string;
};

const StarsRating: React.FC<StarsRatingType> = ({ link = "#/", className }) => {
  const star = index => ({
    full: <StarYellow key={index} index={index} />,
    partial: <StarYellowPartial key={index} />,
  });

  const stars = [...Array(5)].map((_: null, index) =>
    index < 4 ? star(index).full : star(index).partial
  );

  return (
    <Link to={link}>
      <div
        data-testid="star-rating-container"
        className={`${styles.StarRatingList} ${className}`}
      >
        {stars}
      </div>
    </Link>
  );
};

export default StarsRating;
