import * as React from "react";
import PropTypes from "prop-types";
import { ContentFaqsPType } from "../../assets/Types/AcfGroupTypes/ContentFaqsP.type";
import FaqsList from "../../components/FaqsList/FaqsList";
import styles from "./ContentFaqsPContainer.module.scss";

const ContentFaqsPContainer = ({
  contentFaqsList = [],
  contentFaqsSubcopy,
  contentFaqsTitle,
  sectionBackgroundColor = "primary--white",
}: ContentFaqsPType) => {
  if (!contentFaqsList?.length) {
    return null;
  }

  return (
    <section
      className={`pad-top--large pad-bottom--large ${sectionBackgroundColor}`}
    >
      <div data-testid="Div:container" className={`full-width ${styles.grid}`}>
        <div className={styles.header}>
          <h2 className={styles.title}>{contentFaqsTitle}</h2>
          <p dangerouslySetInnerHTML={{ __html: contentFaqsSubcopy }} />
        </div>
        <FaqsList faqsList={contentFaqsList} />
      </div>
    </section>
  );
};

ContentFaqsPContainer.propTypes = {
  contentFaqsTitle: PropTypes.string.isRequired,
  contentFaqsSubcopy: PropTypes.string.isRequired,
  contentFaqsList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      faqsC: PropTypes.shape({
        questionsAnswer: PropTypes.string.isRequired,
      }),
    })
  ),
};

export default ContentFaqsPContainer;
