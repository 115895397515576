import * as React from "react";
import GlobalCapterraReviewsCountQuery from "../../assets/StaticQueries/GlobalCapterraReviewsCount.query";
import { Link } from "gatsby";
import HipaaCompliantLight from "../SVGIcons/Hipaa/HipaaCompliantLight";
import HiTrustCertified from "../SVGIcons/Hipaa/HiTrustCertified";
import StarsRating from "./StarsRating";

import styles from "./ReviewsHomeAB.module.scss";

type ReviewsHomeABPropsType = {
  alter: boolean;
};

const ReviewsHomeAB: React.FC<ReviewsHomeABPropsType> = ({ alter }) => {
  const reviewsCount = GlobalCapterraReviewsCountQuery();
  let hipaaRender;
  let hitrustRender;
  let reviewsCountRender;

  if (alter) {
    reviewsCountRender = (
      <div className={styles.reviewsCountBrand}>
        <Link to="/reviews/">{reviewsCount}</Link>
      </div>
    );

    hipaaRender = (
      <Link to="/features/security/" className={styles.hipaaCompliant}>
        <div className={styles.hipaaCompliantBrand}></div>
      </Link>
    );

    hitrustRender = (
      <Link
        to="/features/security/"
        className={`hide-for-sm ${styles.hitrustCertified}`}
      >
        <div className={styles.hitrustBrand}></div>
      </Link>
    );
  } else {
    reviewsCountRender = (
      <div className={styles.reviewsCount}>
        <Link to="/reviews/">{reviewsCount}</Link>
      </div>
    );

    hipaaRender = (
      <Link to="/features/security/" className={styles.hipaaCompliant}>
        <HipaaCompliantLight />
      </Link>
    );

    hitrustRender = (
      <Link to="/features/security/" className={`${styles.hitrustCertified}`}>
        <HiTrustCertified />
      </Link>
    );
  }

  return (
    <div data-testid="hero-review" className={styles.reviewsContainer}>
      <StarsRating link={"/reviews/"} />
      {reviewsCountRender}
      {hipaaRender}
      {hitrustRender}
    </div>
  );
};

export default ReviewsHomeAB;
