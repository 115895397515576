import React from "react";
import { graphql, HeadProps, PageProps } from "gatsby";
import { FeaturesModulePType } from "../../assets/Types/AcfGroupTypes/FeaturesModuleP/FeaturesModuleP.type";
import { HeroPType } from "../../assets/Types/AcfGroupTypes/HeroP.type";
import { ProfessionalWebsiteGalleryPType } from "../../assets/Types/AcfGroupTypes/ProfessionalWebsiteGalleryP.type";
import { ResourcesSliderPType } from "../../assets/Types/AcfGroupTypes/ResourcesSliderP.type";
import { YoastSEOType } from "../../assets/Types";
import ContentFaqsPContainer from "../../containers/ContentFaqsPContainer/ContentFaqsPContainer";
import FeaturesModulePContainer from "../../containers/FeaturesModulePContainer/FeaturesModulePContainer";
import HeroPContainer from "../../containers/HeroPContainer/HeroPContainer";
import HeroWithVideoContainer from "../../containers/HeroWithVideoContainer";
import HomePageVideoContainer from "../../containers/HomePageVideoContainer/HomePageVideoContainer";
import ProfessionalWebsiteGalleryContainer from "../../containers/ProfessionalWebsiteGalleryContainer";
import ResourcesSliderPContainer from "../../containers/ResourcesSliderPContainer/ResourcesSliderPContainer";
import SecondaryNavMenu from "../../components/Menus/SecondaryNavMenu/SecondaryNavMenu";
import SEO from "gatsby-plugin-wpgraphql-seo";
import TestimonialSliderPContainer from "../../containers/TestimonialSliderPContainer/TestimonialSliderPContainer";
import useOnUserIntent from "../../hooks/useOnUserIntent";
import {
  AddSecondaryCtaType,
  ContentFaqsPType,
  HeroWithVideoPType,
  TestimonialSliderPType,
} from "@types";
import FAQSchema from "../../components/FAQSchema";
import LayoutWithLargeFooterCta from "../../layouts/LayoutWithLargeFooterCta";

type FeatureProps = {
  wpPage: {
    seo: YoastSEOType;
    template: {
      addSecondaryCta: AddSecondaryCtaType;
      heroP: HeroPType;
      heroWithVideoP: HeroWithVideoPType;
      featuresModuleP: FeaturesModulePType;
      testimonialSliderP: TestimonialSliderPType;
      contentFaqsP: ContentFaqsPType;
      resourcesSliderP: ResourcesSliderPType;
      userIntent: Queries.WpFeaturesTemplate_Userintent;
    };
    title: string;
    professionalWebsiteGalleryP: ProfessionalWebsiteGalleryPType;
  };
};

const FeaturesPage: React.FC<PageProps<FeatureProps>> = ({
  data,
  location,
}) => {
  const { wpPage } = data;
  const {
    template: {
      addSecondaryCta,
      heroP,
      heroWithVideoP,
      featuresModuleP,
      testimonialSliderP,
      contentFaqsP,
      resourcesSliderP,
      userIntent,
    },
    title,
    professionalWebsiteGalleryP,
  } = wpPage;
  useOnUserIntent(userIntent);

  const expIds = {
    prodId: "B3Cz_vv_RNakLFCJsM94Hw",
    devId: "byaxO_wtSw-gYXssYII6kA",
  };

  const animation = {
    animationPoster: heroWithVideoP.animationPoster,
    animationSrcMp4: heroWithVideoP.animationSrcMp4,
    animationSrcWebm: heroWithVideoP.animationSrcWebm,
  };

  const HeroAndFeaturesModuleSection =
    heroWithVideoP.enableProductAnimationHero ? (
      <>
        <HeroWithVideoContainer heroP={heroP} animation={animation} />
        <FeaturesModulePContainer
          props={featuresModuleP}
          page={title}
          withPaddingT={false}
          isGreyBg
        />
      </>
    ) : (
      <>
        <HeroPContainer
          props={heroP}
          addSecondaryCta={addSecondaryCta}
          wrap
          page={"Features"}
        />
        <HomePageVideoContainer props={heroWithVideoP} />
        <FeaturesModulePContainer
          props={featuresModuleP}
          page={title}
          withPaddingT={false}
        />
      </>
    );

  return (
    <LayoutWithLargeFooterCta pathname={location.pathname}>
      {wpPage.seo && <SEO post={wpPage} />}
      {HeroAndFeaturesModuleSection}
      {professionalWebsiteGalleryP.galleryList && (
        <ProfessionalWebsiteGalleryContainer
          data={professionalWebsiteGalleryP}
        />
      )}
      <TestimonialSliderPContainer
        props={testimonialSliderP}
        hasCustomerSatisfaction={true}
      />
      <ContentFaqsPContainer
        contentFaqsList={contentFaqsP.contentFaqsList}
        contentFaqsSubcopy={contentFaqsP.contentFaqsSubcopy}
        contentFaqsTitle={contentFaqsP.contentFaqsTitle}
      />
      <ResourcesSliderPContainer
        resourcesSliderTitle={resourcesSliderP.resourcesSliderTitle}
        resourcesSliderSubcopy={resourcesSliderP.resourcesSliderSubcopy}
        resourcesSliderList={resourcesSliderP.resourcesSliderList}
      />
      <SecondaryNavMenu />
    </LayoutWithLargeFooterCta>
  );
};

type HeadData = {
  wpPage: {
    template: {
      contentFaqsP: Queries.WpFeaturesTemplate_Contentfaqsp;
    };
  };
};

export const Head: React.FC<HeadProps<HeadData>> = ({
  data: {
    wpPage: {
      template: {
        contentFaqsP: { contentFaqsList },
      },
    },
  },
}) => (
  <>
    <FAQSchema faqsArray={contentFaqsList} />
  </>
);

export const query = graphql`
  query FeaturesTemplateQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      ...YoastSEOFragment
      template {
        templateName
        ... on WpFeaturesTemplate {
          ...FragmentAddSecondaryCta
          ...FeaturesUserIntentFragment
          templateName
          heroP {
            heroTitle
            heroSubcopy
            heroInputPlaceholder
            heroButtonText
            heroButtonLink
            heroCcText
            fieldGroupName
          }
          heroWithVideoP {
            videoId
            videoTitle
            videoSubcopy
            fieldGroupName
            videoBackgroundImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1144
                    formats: [WEBP, JPG, AUTO]
                    placeholder: BLURRED
                  )
                }
              }
            }
            enableProductAnimationHero
            animationSrcMp4 {
              altText
              mediaItemUrl
            }
            animationSrcWebm {
              altText
              mediaItemUrl
            }
            animationPoster {
              altText
              mediaItemUrl
            }
          }
          featuresModuleP {
            fieldGroupName
            featuresModuleList {
              featuresModuleAssetToggle
              featuresModuleItemTitle
              featuresModuleItemTitleColor
              featuresModuleItemSubcopy
              featuresModuleItemSubcopySecondary
              featuresModuleItemBadgeColor
              featuresModuleItemCta
              featuresModuleItemCtaText
              featuresModuleItemImage {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 1364
                      placeholder: BLURRED
                      formats: [WEBP, JPG, AUTO]
                    )
                  }
                }
              }
              featuresModuleItemVideo {
                altText
                mediaItemUrl
                localFile {
                  publicURL
                }
              }
              featuresModuleItemVideoImage {
                altText
                mediaItemUrl
                localFile {
                  publicURL
                }
              }
            }
            featuresModuleTitle
            featuresModuleSubcopy
          }
          testimonialSliderP {
            testimonialModuleRelationship {
              ... on WpTestimonial {
                id
                title
                testimonialsC {
                  testimonialVideoBackgroundImage {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          width: 800
                          placeholder: BLURRED
                          formats: [WEBP, JPG, AUTO]
                        )
                      }
                    }
                  }
                  testimonialWistiaId
                  testimonialsAuthor
                  testimonialsLabel
                  testimonialsLocation
                  testimonialsQuote
                }
              }
            }
          }
          contentFaqsP {
            contentFaqsTitle
            fieldGroupName
            contentFaqsSubcopy
            contentFaqsList {
              ... on WpFaq {
                id
                title
                faqsC {
                  questionsAnswer
                }
              }
            }
          }
          hiddenFeaturesP {
            fieldGroupName
            hiddenFeaturesCheckbox
          }
          resourcesSliderP {
            resourcesSliderList {
              ... on WpResource {
                resourcesC {
                  resourceType
                  resourcesCardsImage {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          width: 800
                          placeholder: BLURRED
                          formats: [WEBP, JPG, AUTO]
                        )
                      }
                    }
                  }
                  resourcesCardsLink
                  resourceCustomTitle
                }
                slug
                uri
                title
                resourcesCategories {
                  nodes {
                    name
                  }
                }
              }
            }
            resourcesSliderSubcopy
            resourcesSliderTitle
          }
        }
      }
      title
      professionalWebsiteGalleryP {
        galleryTitle
        gallerySubcopy
        galleryList {
          customerWebsite {
            altText
            mediaItemUrl
            localFile {
              publicURL
            }
          }
          customerWebsiteCreateUrl
          customerWebsiteCreateUrlText
          customerWebsiteUrl
          customerWebsiteUrlText
        }
      }
    }
  }
`;

export default FeaturesPage;
