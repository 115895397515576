import React from "react";

const AnimationSection: React.FC<{
  poster: string;
  src: string;
  type: string;
  animationSectionStyles: string;
}> = ({ poster, src, type, animationSectionStyles }) => {
  return (
    <div
      data-testid="hero-animation-section"
      className={animationSectionStyles}
    >
      <video
        data-testid="hero-animation-video"
        width="100%"
        autoPlay
        playsInline
        muted
        loop
        poster={poster}
        className="video"
      >
        <source data-testid="hero-animation-source" src={src} type={type} />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default AnimationSection;
