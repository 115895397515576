import React from "react";
import { ProfessionalWebsiteGalleryPType } from "../../assets/Types/AcfGroupTypes/ProfessionalWebsiteGalleryP.type";

import ProfessionalWebsiteGallery from "../../components/ProfessionalWebsiteGallery";

type Props = {
  data: ProfessionalWebsiteGalleryPType;
  isWhiteBg?: boolean;
};

const ProfessionalWebsiteGalleryContainer: React.FC<Props> = ({
  data,
  isWhiteBg,
}) => {
  const { galleryTitle, gallerySubcopy, galleryList } = data;

  return (
    <ProfessionalWebsiteGallery
      isWhiteBg={isWhiteBg}
      galleryTitle={galleryTitle}
      gallerySubcopy={gallerySubcopy}
      galleryList={galleryList}
    />
  );
};

export default ProfessionalWebsiteGalleryContainer;
