import React from "react";
import { generateFaqsSchema } from "../../utils/generateFaqsSchema";

type DataProps = {
  faqsArray: Queries.Maybe<readonly Queries.Maybe<Queries.WpFaq>[]>;
};

const FAQSchema: React.FunctionComponent<DataProps> = ({ faqsArray }) => {
  const hasFaqs = faqsArray?.length! > 0;

  return (
    <>
      {hasFaqs && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: generateFaqsSchema(faqsArray!),
          }}
        />
      )}
    </>
  );
};

export default FAQSchema;
