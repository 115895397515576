export const generateFaqsSchema = (
  faqsList: readonly Queries.Maybe<Queries.WpFaq>[]
): string => {
  const faqsSchema = faqsList.map(faq => ({
    "@type": "Question",
    name: faq!.title,
    acceptedAnswer: {
      "@type": "Answer",
      text: faq!.faqsC!.questionsAnswer,
    },
  }));

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: faqsSchema,
  };

  return JSON.stringify(structuredData);
};
