import React from "react";
import { GalleryNode } from "../../assets/Types/AcfGroupTypes/ProfessionalWebsiteGalleryP.type";
import ProfessionalSlider from "../Sliders/ProfessionalSlider/ProfessionalSlider";

import styles from "./ProfessionalWebsiteGallery.module.scss";

type Props = {
  galleryTitle: string;
  gallerySubcopy: string;
  galleryList: GalleryNode[];
  isWhiteBg?: boolean;
};

const ProfessionalWebsiteGallery: React.FC<Props> = ({
  galleryTitle,
  gallerySubcopy,
  galleryList,
  isWhiteBg,
}) => {
  const pwGallerySectionStyles = `${styles.pwGallery} ${
    isWhiteBg ? styles.pwGalleryWhite : ""
  }`;

  return (
    <section className={pwGallerySectionStyles}>
      <div className={styles.pwGalleryCopyContainer}>
        <h1 className={styles.pwGalleryHeadLine}>{galleryTitle}</h1>
        <p className={styles.pwGallerySubCopy}>{gallerySubcopy}</p>
      </div>

      <ProfessionalSlider galleryList={galleryList} />
    </section>
  );
};

export default ProfessionalWebsiteGallery;
