import React, { useState } from "react";
import { FeaturesNavQuery } from "../../../assets/StaticQueries/FeaturesNav.query";
import { Link } from "gatsby";
import useWindowSize from "../../../hooks/useWindowSize";
import DownArrow from "../../SVGIcons/DownArrow/DownArrow";

import styles from "./SecondaryNavMenu.module.scss";

const SecondaryNavMenu: React.FC = () => {
  const { menu } = FeaturesNavQuery();
  const { width: windowWidth } = useWindowSize();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const pathname = typeof window !== "undefined" && window.location?.pathname;

  const listItems =
    menu.length &&
    menu.map(({ id, label, path }) => {
      if (path !== pathname) {
        return (
          <li id={id} key={id}>
            <Link to={path}>{label}</Link>
          </li>
        );
      }
      return <li style={{ display: "none" }} key={id} />;
    });

  if (typeof window !== "undefined") {
    const { label } = menu.find(item => item.path === pathname) || {};
    if (pathname && windowWidth < 1024) {
      return (
        <section
          className={`pad-top--large ${styles.menuSecondaryNavContainer}`}
        >
          <h4 className={styles.menuPrimaryNavTitle}>
            Explore our other features
          </h4>
          <div className={styles.menuSecondaryNav}>
            {!isMenuOpen && (
              <div
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className={styles.menuDetailsTitle}
              >
                <div>
                  {label} <DownArrow cls={styles.icon} />
                </div>
              </div>
            )}
            {isMenuOpen && (
              <div>
                <ul>
                  {menu.length &&
                    menu.map(({ id, label, path }) => {
                      if (pathname !== path) {
                        return (
                          <li
                            className={styles.menuPrimaryNavItemsMobile}
                            id={id}
                            key={id}
                          >
                            <Link to={path}>Go to {label}</Link>
                          </li>
                        );
                      } else
                        return (
                          <li
                            key={id + "parent"}
                            className={styles.menuDetailsTitle}
                            onClick={() => setIsMenuOpen(!isMenuOpen)}
                          >
                            <div>
                              {label} <DownArrow cls={styles.icon} />
                            </div>
                          </li>
                        );
                    })}
                </ul>
              </div>
            )}
          </div>
        </section>
      );
    }
  }

  return (
    <section className="pad-top--large">
      <nav className={styles.menuSecondaryNavContainer}>
        <h4 className={styles.menuPrimaryNavTitle}>
          Explore our other features
        </h4>
        <div className={styles.menuPrimaryNavItems}>
          <div>
            <ul>{listItems}</ul>
          </div>
        </div>
      </nav>
    </section>
  );
};

export default SecondaryNavMenu;
