import React from "react";

import { HeroDataType } from "./heroWithVideo.types";

import FormSection from "./components/FormSection";
import ReviewsHomeAB from "../../ReviewsHomeAB";
import AnimationSection from "./components/AnimationSection";
import BlackCheckmark from "../../../components/SVGIcons/BlackCheckmark/BlackCheckmark";

import heroStyles from "./HeroWithVideo.module.scss";

type HeroProps = {
  heroData: HeroDataType;
  isAnimationProductVideo?: boolean;
  isSmTitle?: boolean;
};

export const HeroWithVideo: React.FC<HeroProps> = ({
  heroData: {
    heroButtonLink,
    heroButtonText,
    heroCcText,
    heroInputPlaceholder,
    heroSubcopy,
    heroTitle,
    heroAnimation: { poster, src, type },
  },
  isAnimationProductVideo,
  isSmTitle,
}) => {
  const styleAnimationProduct = isAnimationProductVideo
    ? heroStyles.heroAnimationProdVideo
    : "";

  const titleStyles = `${heroStyles.heroTitle} ${
    isSmTitle ? heroStyles.isSmTitle : undefined
  }`;
  return (
    <section className={`full-width ${heroStyles.hero}`}>
      <div className={heroStyles.heroGrid}>
        <div className={heroStyles.copyContainer}>
          <h1 className={titleStyles} data-testid="hero-title">
            {heroTitle}
          </h1>

          <p className={heroStyles.heroSubcopy} data-testid="hero-subcopy">
            {heroSubcopy}
          </p>

          <FormSection
            sectionStyles={heroStyles.formSection}
            buttonText={heroButtonText}
            buttonLink={heroButtonLink}
            placeholder={heroInputPlaceholder}
          />

          <p className={heroStyles.heroCcText}>
            <BlackCheckmark />
            <span
              data-testid="hero-cc-text"
              dangerouslySetInnerHTML={{ __html: heroCcText }}
            />
          </p>

          <ReviewsHomeAB />
        </div>

        <AnimationSection
          animationSectionStyles={`${heroStyles.heroAnimationVideo} ${styleAnimationProduct}`}
          poster={poster}
          src={src}
          type={type}
        />
      </div>
    </section>
  );
};

export default HeroWithVideo;
